import React from 'react'
import { Layout } from '../components/common'

const AboutPage = () => (
    <Layout isHome>
        <div className="container">
            <h1>¿Quiénes somos?</h1>
            <p>
                Somos una organización de la sociedad civil, 
                orientada a la promoción de los derechos humanos 
                de la diversidad sexual y de género. Brindar asesoría 
                a las personas de la población LGBTTTI, además de realizar 
                acciones políticas, artísticas, audiovisuales y culturales 
                que coadyuven a disminuir, prevenir y atender la discriminación 
                hacia las personas LGBTTTI en el Estado de México.
            </p>
            <h2>Razón Social</h2>
            <p>
                Brindar asesoría psicológica, médica y legal a las personas
                de la población Lésbico, Gay, Bisexual, Travesti, Transexual,
                Transgénero e Intersexual (LGBTTTI).
            </p>
            <p>
                Promover la educación sexual y prevenir actitudes homofóbicas,
                lesfóbicas, bifóbicas, transfóbicas o machistas por medio de talleres
                e capacitación, pláticas, conferencias y actividades artísticas.
            </p>
            <p>
                Diseño e implementación de campañas publicitarias con perspectiva
                de género enfocadas a temáticas de salud sexual, prevención de
                Infecciones de Transmisión Sexual (ITS), especialmente VIH/SIDA,
                violencia de género y diversidad sexual.
            </p>
            <h2>Misión</h2>
            <p>
                Somos una organización de la sociedad civil, orientada a la promoción
                de los derechos humanos de la diversidad sexual y de género. Brindar
                asesoría a las personas de la población LGBTTTI, además de realizar acciones
                políticas, artísticas, audiovisuales y culturales que coadyuven a disminuir,
                prevenir y atender la discriminación hacia las personas LGBTTTI en el Estado de México.
            </p>
            <h2>Visión</h2>
            <p>
                Convertirnos en un referente en la defensa de los derechos 
                humanos de la población LGBTTTI en el Estado de México. 
                Consolidarnos como un proyecto audiovisual que visibiliza 
                a la población LGBTTTI sin estereotipos, libre de prejuicios 
                y con perspectiva de género.
            </p>
        </div>
    </Layout>
)

export default AboutPage
